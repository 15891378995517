import { authParamName, redirectParamName } from "@/common/axshare/routing";
import plugins from "@/common/inspect/plugins";
import { getQueryVarString } from "@/common/lib";
import { combineQueryWithHash } from "@/common/utils";
import { getRouteParam } from "@/router/utils";
import { useQueryClient } from "@tanstack/vue-query";
import { getPrototypeRedirectCommentInfo } from "ls/api/cloud/prototype";
import { queries } from "ls/features/files/queries";
import { useAccountService } from "ls/features/user/useAccountService";
import NotFound from "ls/pages/NotFound.vue";
import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router";
import { useAuthGuard as addAuthGuard } from "./auth-guard";
import { toFileInspect, toFileOverview, toFilePreview, toHome, toManage } from "./builders";
import { folderParameterName, organizationParameterName, routeNames, screenParameterName, shortcutParameterName, workspaceParameterName } from "./constants";
import { redirectOnSuccessfulSignIn } from "./redirects";
import { createDiskoUrl } from "./utils";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: routeNames.home,
    redirect: {
      name: routeNames.manage,
    },
  },
  {
    path: "/new",
    redirect() {
      return toManage({ new: true });
    },
  },
  {
    path: "/fs",
    redirect: {
      name: routeNames.manage,
    },
  },
  {
    path: "/fs/manage",
    name: routeNames.manage,
    component: () => import("ls/features/workspaces/Manage.vue"),
    meta: {
      useMinWidth: true,
    },
    children: [
      {
        path: `:${workspaceParameterName}/:${folderParameterName}?`,
        name: routeNames.workspace,
        component: () => import("ls/features/workspaces/WorkspaceView.vue"),
        props: true,
      },
      {
        path: "/fs/recents",
        name: routeNames.recents,
        component: () => import("ls/features/recents/RecentsView.vue"),
        props: true,
      },
      {
        path: `/project/:${shortcutParameterName}`,
        alias: `/project/:${shortcutParameterName}/overview`,
        name: routeNames.fileOverview,
        component: () => import("ls/features/files/FileView.vue"),
        props: true,
        children: [
          // @ts-expect-error component: null, this route always redirects
          {
            name: routeNames.fileShow,
            path: "show",
            component: null,
            async beforeEnter(to) {
              const shortcut = getRouteParam(to.params, shortcutParameterName);
              if (!shortcut) {
                return toHome();
              }

              // replace hash to query and combine hash with query
              if (to.hash) {
                const query = combineQueryWithHash(to.query, to.hash);
                return {
                  name: routeNames.fileShow,
                  params: to.params,
                  query,
                  replace: true,
                };
              }

              // Used to redirect to member link if Share Link is disabled.
              // Here, using parameters from hash, we determine which page in the cloud to redirect to,
              // Also if there is no page id in the link parameters, we make a request to the server.
              // If there is no page Id, then we redirect to the overview page.
              const screen = getQueryVarString(to.fullPath, "id");
              const page = getQueryVarString(to.fullPath, "p");
              let startPage = screen || page;

              if (!startPage) {
                try {
                  const queryClient = useQueryClient();
                  const { Pages: pages } = await queryClient.ensureQueryData(queries.pages(shortcut));
                  if (pages && pages.length) {
                    startPage = pages[0].Id;
                  }
                } catch {
                  return toFileOverview(shortcut);
                }
              }

              if (!startPage || getQueryVarString(to.fullPath, "open-share-dialog") === "true") {
                return {
                  ...toFileOverview(shortcut),
                  query: to.query,
                };
              }

              const gids = getQueryVarString(to.fullPath, "g");
              const route = gids.includes(plugins.handoff.gid.toString())
                ? toFileInspect(shortcut, startPage)
                : toFilePreview(shortcut, startPage);

              return {
                ...route,
                query: to.query,
              };
            },
          },
          {
            name: routeNames.fileHistory,
            path: "history",
            component: () => import("ls/features/files/FileView.vue"),
            props: true,
          },
          // @ts-expect-error component: null, this route always redirects
          {
            name: routeNames.fileComments,
            path: "comments",
            component: null,
            async beforeEnter(to) {
              const shortcut = getRouteParam(to.params, shortcutParameterName);
              if (!shortcut) return true;

              const projectOverview = toFileOverview(shortcut);
              try {
                const commentInfo = await getPrototypeRedirectCommentInfo(shortcut);

                if (!commentInfo || !commentInfo.IssueCode) {
                  return projectOverview;
                }

                return toFilePreview(shortcut, commentInfo.ShortPageId, { comment: commentInfo.IssueCode });
              } catch {
                return projectOverview;
              }
            },
          },
        ],
      },
      {
        name: routeNames.filePreview,
        path: `/project/:${shortcutParameterName}/preview/:${screenParameterName}?`,
        component: () => import("ls/features/file-preview/FilePreview.vue"),
        props: true,
      },
      {
        name: routeNames.fileInspect,
        path: `/project/:${shortcutParameterName}/inspect/:${screenParameterName}?`,
        component: () => import("ls/features/file-preview/FilePreview.vue"),
        props: true,
      },
      {
        name: routeNames.fileBuild,
        path: `/project/:${shortcutParameterName}/build/:${screenParameterName}?`,
        component: () => import("ls/features/clickthrough/ClickthroughBuild.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/settings",
    name: routeNames.settings,
    component: () => import("ls/features/settings/Settings.vue"),
    redirect: {
      name: routeNames.settingsProfile,
    },
    meta: {
      useMinWidth: true,
    },
    children: [
      {
        path: "account",
        name: routeNames.settingsProfile,
        component: () => import("ls/features/settings/SettingsAccount.vue"),
        props: true,
      },
      {
        path: "notifications",
        name: routeNames.settingsNotifications,
        component: () => import("ls/features/settings/SettingsNotifications.vue"),
        props: true,
      },
      {
        path: `/orgs/:${organizationParameterName}`,
        name: routeNames.settingsOrganization,
        props: true,
        redirect: {
          name: routeNames.settingsOrganizationGeneral,
        },
        children: [
          {
            path: "general",
            name: routeNames.settingsOrganizationGeneral,
            component: () => import("ls/features/organizations/SettingsOrganization.vue"),
            props: true,
          },
          {
            path: "members",
            name: routeNames.settingsOrganizationMembers,
            component: () => import("ls/features/organizations/SettingsOrganizationMembers.vue"),
            props: true,
          },
          {
            path: "authentication",
            name: routeNames.settingsOrganizationAuthentication,
            component: () => import("ls/features/organizations/SettingsOrganizationAuthentication.vue"),
            props: true,
          },
          {
            path: "authentication/sso",
            name: routeNames.settingsOrganizationAuthenticationSso,
            component: () => import("ls/features/organizations/SettingsOrganizationAuthenticationSso.vue"),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: routeNames.signIn,
    component: () => import("ls/features/user/SignIn.vue"),
    meta: {
      requiresAuth: false,
    },
    async beforeEnter(to) {
      const accountService = useAccountService();

      // we may be already authenticated from global auth guard
      // if that's the case just follow redirect
      if (accountService.isAuthenticated.value) {
        const location = redirectOnSuccessfulSignIn(to);
        return location;
      }

      // Prevent cycle redirects - if auth param presented then just return
      if (!to.query[authParamName]) {
        // If account service is available then use its login page
        const redirectParam = to.query[redirectParamName];
        const hasRequiredParams = !!redirectParam;
        if (!accountService.isSameAsApiHost.value || !hasRequiredParams) {
          const accountServiceLoginUrl = accountService.getLoginFullUrl(to);
          if (!accountServiceLoginUrl) {
            throw new Error("Attempted to navigate to account service for login but URL was undefined");
          }
          window.location.href = accountServiceLoginUrl;
          return false;
        }
      }

      // if account service is already in the error state,
      // then it's a bounce from a different route
      // and we don't have to check session again
      if (accountService.error.value) {
        return true;
      }
      // but when no error and session is valid
      // continue with redirect
      const isAuthenticated = await accountService.checkSession();
      if (isAuthenticated) {
        const location = redirectOnSuccessfulSignIn(to);
        return location;
      }
      return true;
    },
  },
  {
    path: "/join-axure",
    name: routeNames.joinAxure,
    component: () => import("ls/features/user/JoinAxure.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/resetpassword",
    name: routeNames.resetPassword,
    component: () => import("ls/features/user/ResetPassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/resetpasswordexpired",
    name: routeNames.resetPasswordExpired,
    component: () => import("ls/features/user/ResetPasswordExpired.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/upload-from-sketch",
    name: "upload-from-sketch",
    component: () => import("ls/features/desktop-app/DesignAppsExport.vue"),
  },
  {
    path: `/project-not-shared/:${workspaceParameterName}/:${shortcutParameterName}`,
    name: routeNames.fileNotShared,
    component: () => import("ls/features/files/FileNotShared.vue"),
    props: true,
  },
  {
    path: "/project-deleted",
    name: routeNames.fileDeleted,
    component: () => import("ls/features/files/FileDeleted.vue"),
    props: true,
  },
  {
    path: `/invite/:${shortcutParameterName}`,
    name: routeNames.inviteFromRP,
    redirect(to) {
      const shortcut = getRouteParam(to.params, shortcutParameterName);
      if (!shortcut) return toHome();

      return toFileOverview(shortcut, { invite: true });
    },
  },
  // capture Specs '/file/' urls and perform location change to Specs
  {
    path: `/file/:${shortcutParameterName}`,
    meta: {
      requiresAuth: false,
    },
    redirect(to) {
      const shortcut = getRouteParam(to.params, shortcutParameterName);
      if (!shortcut) {
        return toHome();
      }

      const url = createDiskoUrl(shortcut, { to });
      window.location.href = url;
      return toHome();
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: routeNames.notFound,
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  },
];

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

addAuthGuard(router);
